import { createContext, useContext, useMemo, useState } from "react";

const CategoryValueContext = createContext();
const CategoryActionsContext = createContext();

export function CategoryProvider({ children }) {
  const [categories, setCategories] = useState(null);
  const actions = useMemo(
    () => ({
      setCategories: (data) => {
        setCategories(data);
      }
    }),
    []
  );

  return (
    <CategoryActionsContext.Provider value={actions}>
      <CategoryValueContext.Provider value={categories}>
        {children}
      </CategoryValueContext.Provider>
    </CategoryActionsContext.Provider>
  );
}

export function useCategoryValue() {
  const value = useContext(CategoryValueContext);
  if (value === undefined) {
    throw new Error("useCategoryValue should be used within CategoryProvider");
  }
  return value;
}

export function useCategoryActions() {
  const value = useContext(CategoryActionsContext);
  if (value === undefined) {
    throw new Error(
      "useCategoryActions should be used within CategoryProvider"
    );
  }
  return value;
}
