import { Navigate, Outlet } from "react-router-dom";
import { useUserValue } from "src/contexts/userProvider";

function PrivateRoute({ authentication, Layout }) {
  const isAuthenticated = useUserValue() || false;

  const Page = () => (
    <Layout>
      <Outlet />
    </Layout>
  );

  if (authentication) {
    return !isAuthenticated ? <Navigate to="/login" /> : <Page />;
  } else {
    return !isAuthenticated ? <Page /> : <Navigate to="/" />;
  }
}

export default PrivateRoute;
