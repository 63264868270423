import logo from "@/images/navbar/logo.png";
import { useEffect, useRef, useState } from "react";
import { NavLink, matchRoutes, useLocation } from "react-router-dom";
import router from "src/routes";
import * as paths from "src/routes/paths";

const MENUS = [
  {
    title: "카테고리 관리",
    titleIcon: "icon-nav-file-text",
    link: paths.category,
    childs: [
      {
        title: "카테고리 현황",
        link: paths.getPath(paths.category, paths.categoryList)
      },
      {
        title: "카테고리 등록",
        link: paths.getPath(paths.category, paths.categoryCreate)
      }
    ]
  },
  {
    title: "프로젝트 관리",
    titleIcon: "icon-nav-layout",
    link: paths.project,
    childs: [
      {
        title: "프로젝트 현황",
        link: paths.getPath(paths.project, paths.projectList)
      },
      {
        title: "프로젝트 등록",
        link: paths.getPath(paths.project, paths.projectCreate)
      }
    ]
  },
  {
    title: "관리자 관리",
    titleIcon: "icon-nav-layout",
    link: paths.admin,
    childs: [
      {
        title: "관리자 현황",
        link: paths.getPath(paths.admin, paths.adminList)
      },
      {
        title: "관리자 등록",
        link: paths.getPath(paths.admin, paths.adminCreate)
      }
    ]
  }
];

function Navbar() {
  return (
    <nav className="nav-bar">
      <div className="nav-bar-wrap">
        <div className="logo-cont">
          <img src={logo} alt="logo image" className="logo" />
          <button
            className="btn-menu"
            onClick={() => {
              document.querySelector(".body-wrap").classList.toggle("fold");
            }}
          >
            <i className="icon-menubar"></i>
          </button>
          <button
            className="btn-n-close"
            onClick={() => {
              document.querySelector(".body-wrap").classList.toggle("open");
            }}
          >
            <i className="icon-close"></i>
          </button>
        </div>
        <ul className="link-items">
          <NavbarItems />
        </ul>
      </div>
    </nav>
  );
}

function NavbarItems() {
  const menuRefs = useRef([]);
  const location = useLocation();
  const [baseURL, setBaseURL] = useState("");

  useEffect(() => {
    const matches = matchRoutes(router, location);
    setBaseURL(matches[0]?.route?.path);
  }, [location]);

  const itemClicked = (index) => {
    const target = menuRefs.current[index];
    if (!target.classList.contains("open")) {
      menuRefs.current.forEach((item, itemIndex) => {
        item.classList.remove("open");
        if (itemIndex === menuRefs.current.length - 1) {
          target.classList.add("open");
        }
      });
    } else {
      target.classList.toggle("open");
    }
  };

  return (
    <>
      {MENUS.map((item, itemIndex) => (
        <li key={`navbarItem ${itemIndex}`}>
          <a
            href="javascript:;"
            className={`item ${baseURL === item.link ? "active" : ""}`}
            ref={(el) => (menuRefs.current[itemIndex] = el)}
            onClick={() => itemClicked(itemIndex)}
          >
            <i className={item.titleIcon}></i>
            <span>{item.title}</span>
          </a>
          <ul className="sub-nav">
            {item.childs.map((child, childIndex) => (
              <li key={`navbarItem ${itemIndex} child ${childIndex}`}>
                <NavLink
                  to={child.link}
                  className={({ isActive }) =>
                    "sub-item " + (isActive ? "active" : "")
                  }
                >
                  {child.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </>
  );
}

export default Navbar;
