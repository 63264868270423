export const login = "/login";

export const category = "/category";
export const categoryList = "list";
export const categoryCreate = "create";
export const categoryDetail = ":id";

export const project = "/project";
export const projectList = "list";
export const projectCreate = "create";
export const projectDetail = ":id";

export const admin = "/admin";
export const adminList = "list";
export const adminCreate = "create";
export const adminDetail = ":id";

export const getPath = (base, ...paths) => {
  if (!base || !Array.isArray(paths) || paths.length < 1) return "";
  return paths.reduce((prev, current) => (prev += `/${current}`), base);
};
