import { Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import customAxios from "src/common/axios";
import { useAdminValue, useAdminActions } from "src/contexts/adminProvider";
import { ExclamationCircleFilled } from "@ant-design/icons";

function AdminList() {
  const admins = useAdminValue();
  const [loading, setLoading] = useState(false);
  const { setAdmins } = useAdminActions();

  useEffect(() => {
    getAdminList();
  }, []);

  const getAdminList = () => {
    setLoading(true);
    customAxios
      .get("/admin/list")
      .then((res) => {
        setAdmins(res.data.data.map((admin) => ({ ...admin, checked: false })));
      })
      .catch((err) => {
        console.error(err);
        message.error("관리자 목록을 불러오는 중에 오류가 발생하였습니다.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const createButtonClicked = () => {
    navigate("/admin/create");
  };

  const detailButtonClicked = (adminId) => {
    navigate(`/admin/${adminId}`);
  };

  const { confirm } = Modal;
  const deleteButtonClicked = () => {
    const adminIdList = showAdminList
      .filter((admin) => admin.checked)
      .map((admin) => admin.adminId);

    if (adminIdList.length === 0) {
      return message.warning("삭제할 관리자를 선택해주세요.");
    }

    confirm({
      title: `${adminIdList.length}개의 관리자를 삭제하시겠습니까?`,
      icon: <ExclamationCircleFilled />,
      okText: "삭제",
      okType: "danger",
      cancelText: "취소",
      onOk() {
        setLoading(true);
        customAxios
          .post("/admin/delete", adminIdList)
          .then((res) => {
            setLoading(false);
            getAdminList();
            message.success("성공적으로 삭제되었습니다.");
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
            message.error("삭제 중 오류가 발생하였습니다.");
          });
      }
    });
  };

  const getHashFilterOption = () => {
    try {
      if (
        location.hash !== undefined &&
        location.hash !== null &&
        location.hash !== ""
      ) {
        const hash = location.hash.substring(1, location.hash.length);

        const filterOptions = JSON.parse(decodeURI(hash));
        filterOptions.page = parseInt(filterOptions.page) || 1;

        return JSON.parse(decodeURI(hash));
      }
    } catch (err) {}

    return {
      search: "",
      orderColumn: "adminPriority",
      order: "asc",
      count: 10,
      page: 1
    };
  };

  const [filterOption, setFilterOption] = useState(getHashFilterOption());
  const [totalPage, setTotalPage] = useState(1);
  const [showAdminList, setShowAdminList] = useState([]);

  useEffect(() => {
    if (admins) {
      const _showAdminList = admins
        .filter((admin) =>
          [admin.adminId, admin.adminName].some(
            (val) => val && val.includes(filterOption.search)
          )
        )
        .sort((a, b) => {
          if (a[filterOption.orderColumn] < b[filterOption.orderColumn]) {
            return filterOption.order === "asc" ? -1 : 1;
          }
          if (a[filterOption.orderColumn] > b[filterOption.orderColumn]) {
            return filterOption.order === "asc" ? 1 : -1;
          }
          return 0;
        });

      setTotalPage(Math.ceil(_showAdminList.length / filterOption.count));

      const startIndex = (filterOption.page - 1) * filterOption.count;
      const endIndex = filterOption.page * filterOption.count;
      setShowAdminList(_showAdminList.slice(startIndex, endIndex));

      window.history.pushState({}, "", `#${JSON.stringify(filterOption)}`);
    }
  }, [admins, filterOption]);

  const changeFilterOption = (option) => {
    if (option.search || option.count) {
      option.page = 1;
    }
    setFilterOption({ ...filterOption, ...option });
  };

  const sortClicked = (orderColumn) => {
    if (filterOption.orderColumn === orderColumn) {
      changeFilterOption({
        order: filterOption.order === "asc" ? "desc" : "asc"
      });
    } else {
      changeFilterOption({
        orderColumn,
        order: "asc"
      });
    }
  };

  const getSortClassName = (orderColumn) => {
    if (filterOption.orderColumn === orderColumn) {
      return filterOption.order === "asc" ? "sort-up" : "sort-down";
    }
    return "";
  };

  const [allChecked, setAllChecked] = useState(false);

  const allCheckClicked = () => {
    const checked = !allChecked;
    setAllChecked(checked);
    setShowAdminList(showAdminList.map((admin) => ({ ...admin, checked })));
  };

  const adminCheckClicked = (admin) => {
    const checked = !admin.checked;

    admin.checked = checked;
    if (checked && showAdminList.every((admin) => admin.checked)) {
      setAllChecked(true);
    } else if (!checked && allChecked) {
      setAllChecked(false);
    } else {
      setShowAdminList([...showAdminList]);
    }
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">관리자 현황</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">관리자 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                관리자 현황
              </li>
            </ol>
          </div>
        </div>
        <button
          className="btns btns-icon-before"
          type="button"
          onClick={createButtonClicked}
        >
          <i className="icon-useradd"></i>관리자 등록
        </button>
      </div>
      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col">
                <div className="card-items">
                  <div className="table-wrap tb-member-list">
                    <div className="tb-head-wrap">
                      <div className="search-wrap">
                        <div className="input-box search">
                          <input
                            type="text"
                            placeholder="ID, 이름 검색"
                            onKeyUp={({ target }) =>
                              changeFilterOption({ search: target.value })
                            }
                          />
                          <button type="button" className="btn-search">
                            <i className="icon-search"></i>
                          </button>
                        </div>
                      </div>

                      <div className="tb-toolbar">
                        <div className="btn-wrap">
                          <button
                            className="btns btns-outline btns-icon-before btns-md"
                            type="button"
                            onClick={deleteButtonClicked}
                          >
                            <i className="icon-chattext"></i>삭제
                          </button>
                        </div>
                        <div className="select-box">
                          <select
                            value={filterOption.count}
                            onChange={({ target }) =>
                              changeFilterOption({
                                count: target.value
                              })
                            }
                          >
                            <option value="10">10개</option>
                            <option value="20">20개</option>
                            <option value="30">30개</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="tb-overflow">
                      <table className="table">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col">
                              <label className="checkbox-wrap">
                                <input
                                  type="checkbox"
                                  name="check-all"
                                  checked={allChecked}
                                  onClick={() => allCheckClicked()}
                                  onChange={() => {}}
                                />
                                <span className="label-checkbox"></span>
                              </label>
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName("adminId")}`}
                              onClick={() => sortClicked("adminId")}
                            >
                              아이디
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "adminName"
                              )}`}
                              onClick={() => sortClicked("adminName")}
                            >
                              관리자명
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "adminDescription"
                              )}`}
                              onClick={() => sortClicked("adminDescription")}
                            >
                              설명
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "adminViewYn"
                              )}`}
                              onClick={() => sortClicked("adminViewYn")}
                            >
                              사용 여부
                            </th>
                            <th scope="col">상세</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showAdminList !== null &&
                            showAdminList.map((admin) => (
                              <tr key={admin.adminId}>
                                <td>
                                  <label className="checkbox-wrap">
                                    <input
                                      type="checkbox"
                                      name={admin.adminId}
                                      checked={admin.checked}
                                      onClick={() => adminCheckClicked(admin)}
                                      onChange={() => {}}
                                    />
                                    <span className="label-checkbox"></span>
                                  </label>
                                </td>
                                <td>{admin.adminId}</td>
                                <td>{admin.adminName}</td>
                                <td>{admin.adminDescription}</td>
                                <td>{`${
                                  admin.adminActiveYn === "Y"
                                    ? "사용"
                                    : "미사용"
                                }`}</td>
                                <td>
                                  <button
                                    className="btns btns-gray btns-sm detail"
                                    type="button"
                                    onClick={() =>
                                      detailButtonClicked(admin.adminId)
                                    }
                                  >
                                    상세보기
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="paging-wp">
                      <button
                        type="button"
                        className="btn-prev"
                        onClick={() =>
                          changeFilterOption({ page: filterOption.page - 1 })
                        }
                        disabled={filterOption.page === 1}
                      >
                        <i className="icon-arrow-left"></i>
                      </button>
                      {Array.from(
                        { length: totalPage },
                        (v, i) => (v = i + 1)
                      ).map((val) => (
                        <button
                          type="button"
                          className={`${
                            val === filterOption.page ? "active" : ""
                          }`}
                          onClick={() => changeFilterOption({ page: val })}
                          key={`pageNavigateButton${val}`}
                        >
                          {val}
                        </button>
                      ))}
                      <button
                        type="button"
                        className="btn-next"
                        onClick={() =>
                          changeFilterOption({ page: filterOption.page + 1 })
                        }
                        disabled={filterOption.page === totalPage}
                      >
                        <i className="icon-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default AdminList;
