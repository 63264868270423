import { createContext, useContext, useMemo, useState } from "react";

const AdminValueContext = createContext();
const AdminActionsContext = createContext();

export function AdminProvider({ children }) {
  const [admins, setAdmins] = useState(null);
  const actions = useMemo(
    () => ({
      setAdmins: (data) => {
        setAdmins(data);
      }
    }),
    []
  );

  return (
    <AdminActionsContext.Provider value={actions}>
      <AdminValueContext.Provider value={admins}>
        {children}
      </AdminValueContext.Provider>
    </AdminActionsContext.Provider>
  );
}

export function useAdminValue() {
  const value = useContext(AdminValueContext);
  if (value === undefined) {
    throw new Error("useAdminValue should be used within AdminProvider");
  }
  return value;
}

export function useAdminActions() {
  const value = useContext(AdminActionsContext);
  if (value === undefined) {
    throw new Error("useAdminActions should be used within AdminProvider");
  }
  return value;
}
