import React, { useState } from "react";
import customAxios from "src/common/axios";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";

function CategoryCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryViewYn, setCategoryViewYn] = useState("Y");

  const listButtonClicked = () => {
    navigate("/category/list");
  };

  const createCategory = () => {
    const categoryData = {
      categoryName,
      categoryViewYn
    };

    if (
      Object.values(categoryData).some(
        (v) => v === undefined || v === null || v === ""
      )
    ) {
      return message.warning("모든 정보를 입력해주세요.");
    }

    setLoading(true);

    customAxios
      .post("/category/insert", categoryData)
      .then((res) => {
        const id = res.data.data.categoryId;
        message.success("성공적으로 등록되었습니다.");
        navigate(`/category/${id}`);
      })
      .catch((err) => {
        console.error(err);
        message.error("등록 중 오류가 발생하였습니다.");
        setLoading(false);
      });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">카테고리 등록</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">카테고리 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                카테고리 등록
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col col-12">
                <div className="card-items h-auto">
                  <div className="content-display operate-write">
                    <div className="cont-row-wrap">
                      <div className="cont-wrap">
                        <div className="list-cont">
                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">카테고리명</p>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="카테고리명을 입력하세요"
                                  value={categoryName}
                                  onChange={(e) =>
                                    setCategoryName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">사용 여부</p>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={categoryViewYn === "Y"}
                                  onChange={() => setCategoryViewYn("Y")}
                                />
                                <span className="label-radio" /> 사용
                              </label>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={categoryViewYn === "N"}
                                  onChange={() => setCategoryViewYn("N")}
                                />
                                <span className="label-radio" /> 미사용
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="cont-btns-wrap">
                        <div className="btns-wrap"></div>
                        <div className="btns-wrap">
                          <button
                            type="button"
                            className="btns btns-outline"
                            onClick={listButtonClicked}
                          >
                            목록
                          </button>
                          <button
                            type="button"
                            className="btns"
                            onClick={createCategory}
                          >
                            등록
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default CategoryCreate;
