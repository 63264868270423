import axios from "axios";
import queryString from "query-string";

const customAxios = axios.create({
  baseURL: "/api",
  timeout: 30 * 1000
});

customAxios.interceptors.request.use(
  function (config) {
    try {
      config.headers["jwt-auth-token"] = sessionStorage.getItem("token") || "";

      /**
       * ? get 요청 시, data에 있는 값들을 queryString으로 자동 변환해주는 코드
       */
      if (config.method === "get" && config.url && config.data) {
        config.url = queryString.stringifyUrl({
          url: config.url,
          query: config.data
        });
      }
    } catch (err) {
      console.error("axios instance - queryString error");
      console.error(err);
    }

    return config;
  },
  function (error) {
    console.error("axios instance - request error");
    return Promise.reject(error);
  }
);

export default customAxios;
