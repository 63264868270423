import Header from "@/components/layout/Header";
import Navbar from "@/components/layout/Navbar";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <div className="body-wrap">
      <Navbar />
      <Header />
      <div className="main-wrap">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
