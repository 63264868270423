import { useUserValue } from "src/contexts/userProvider";

function Header() {
  const user = useUserValue();

  return (
    <header className="header">
      <div className="top-user-info">
        <button
          className="btn-menu"
          onClick={() => {
            document
              .getElementsByClassName("body-wrap")[0]
              .classList.toggle("open");
          }}
        >
          <i className="icon-menubar"></i>
        </button>

        <button type="button" className="btn-profile">
          <span className="name">{user.adminName}</span>님
          <span className="hello">, 안녕하세요!</span>
        </button>
      </div>
    </header>
  );
}

export default Header;
