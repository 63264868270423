import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import customAxios from "src/common/axios";
import {
  useCategoryValue,
  useCategoryActions
} from "src/contexts/categoryProvider";
import { Modal, Spin, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ReactSortable } from "react-sortablejs";

function CategoryList() {
  const categories = useCategoryValue();
  const { setCategories } = useCategoryActions();
  const [loading, setLoading] = useState(false);
  const [selectedRowList, setSelectedRowList] = useState([]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    setLoading(true);
    customAxios
      .get("/category/list")
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        message.error("카테고리 목록을 불러오는 중에 오류가 발생하였습니다.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const createButtonClicked = () => {
    navigate("/category/create");
  };

  const detailButtonClicked = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  const { confirm } = Modal;
  const deleteButtonClicked = () => {
    if (selectedRowList.length === 0) {
      return message.warning("삭제할 카테고리를 선택해주세요.");
    }

    confirm({
      title: `${selectedRowList.length}개의 카테고리를 삭제하시겠습니까?`,
      icon: <ExclamationCircleFilled />,
      okText: "삭제",
      okType: "danger",
      cancelText: "취소",
      onOk() {
        setLoading(true);

        customAxios
          .post("/category/delete", selectedRowList)
          .then((res) => {
            setLoading(false);
            getCategoryList();
            message.success("성공적으로 삭제되었습니다.");
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
            message.error("삭제 중 오류가 발생하였습니다.");
          });
      }
    });
  };

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (categories && selectedRowList && Array.isArray(selectedRowList)) {
      setAllChecked(categories.length === selectedRowList.length);
    }
  }, [selectedRowList]);

  const allCheckClicked = () => {
    if (allChecked) {
      setSelectedRowList([]);
    } else {
      setSelectedRowList(categories.map((category) => category.categoryId));
    }
  };

  const categoryCheckClicked = ({ id, currentTarget }) => {
    const checked = currentTarget.checked;

    if (checked) {
      selectedRowList.push(id);
      setSelectedRowList([...selectedRowList]);
    } else {
      const idx = selectedRowList.findIndex((i) => i === id);
      selectedRowList.splice(idx, 1);
      setSelectedRowList([...selectedRowList]);
    }
  };

  const sortHandler = ({ oldIndex, newIndex, id }) => {
    if ([oldIndex, newIndex, id].some((v) => v === undefined || v === null)) {
      console.error("parameter 오류");
      return message.error("처리 중 오류가 발생하였습니다.");
    }

    customAxios
      .patch(`/category/priority/${id}`, {
        oldIndex: oldIndex + 1,
        newIndex: newIndex + 1
      })
      .catch((err) => {
        console.error(err);
        return message.error("정렬 순서 변경 중 오류가 발생하였습니다.");
      });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">카테고리 현황</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">카테고리 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                카테고리 현황
              </li>
            </ol>
          </div>
        </div>
        <button
          className="btns btns-icon-before"
          type="button"
          onClick={createButtonClicked}
        >
          <i className="icon-useradd"></i>카테고리 등록
        </button>
      </div>

      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col">
                <div className="card-items">
                  <div className="table-wrap tb-member-list">
                    <div className="tb-head-wrap">
                      <div className="tb-toolbar">
                        <div className="btn-wrap">
                          <button
                            className="btns btns-outline btns-icon-before btns-md"
                            type="button"
                            onClick={deleteButtonClicked}
                          >
                            <i className="icon-chattext"></i>삭제
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="tb-overflow">
                      <table className="table">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col">
                              <label className="checkbox-wrap">
                                <input
                                  type="checkbox"
                                  name="check-all"
                                  checked={allChecked}
                                  onClick={() => allCheckClicked()}
                                  onChange={() => {}}
                                />
                                <span className="label-checkbox"></span>
                              </label>
                            </th>
                            <th scope="col">카테고리명</th>
                            <th scope="col">노출순서</th>
                            <th scope="col">사용 여부</th>
                            <th scope="col">상세</th>
                          </tr>
                        </thead>
                        {categories && (
                          <ReactSortable
                            tag="tbody"
                            list={categories}
                            setList={(newState) => setCategories(newState)}
                            onSort={({ oldIndex, newIndex, item }) =>
                              sortHandler({
                                oldIndex,
                                newIndex,
                                id: item.dataset.categoryId
                              })
                            }
                          >
                            {categories.map((category, categoryIndex) => (
                              <tr
                                key={category.categoryId}
                                data-category-id={category.categoryId}
                              >
                                <td>
                                  <label className="checkbox-wrap">
                                    <input
                                      type="checkbox"
                                      name={category.categoryId}
                                      checked={selectedRowList.includes(
                                        category.categoryId
                                      )}
                                      onClick={({ currentTarget }) =>
                                        categoryCheckClicked({
                                          currentTarget,
                                          id: category.categoryId
                                        })
                                      }
                                      onChange={() => {}}
                                    />
                                    <span className="label-checkbox"></span>
                                  </label>
                                </td>
                                <td>{category.categoryName}</td>
                                <td>{categoryIndex + 1}</td>
                                <td>{`${
                                  category.categoryViewYn === "Y"
                                    ? "사용"
                                    : "미사용"
                                }`}</td>
                                <td>
                                  <button
                                    className="btns btns-gray btns-sm detail"
                                    type="button"
                                    onClick={() =>
                                      detailButtonClicked(category.categoryId)
                                    }
                                  >
                                    상세보기
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </ReactSortable>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default CategoryList;
