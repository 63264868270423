import { Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import customAxios from "src/common/axios";
import NumberToOptions from "src/components/common/NumberToOptions";
import {
  useProjectValue,
  useProjectActions
} from "src/contexts/projectProvider";
import { ExclamationCircleFilled } from "@ant-design/icons";

function ProjectList() {
  const projects = useProjectValue();
  const [loading, setLoading] = useState(false);
  const { setProjects } = useProjectActions();

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = () => {
    setLoading(true);
    customAxios
      .get("/project/list")
      .then((res) => {
        setProjects(
          res.data.data.map((project) => ({ ...project, checked: false }))
        );
      })
      .catch((err) => {
        console.error(err);
        message.error("프로젝트 목록을 불러오는 중에 오류가 발생하였습니다.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const createButtonClicked = () => {
    navigate("/project/create");
  };

  const detailButtonClicked = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const { confirm } = Modal;
  const deleteButtonClicked = () => {
    const projectIdList = showProjectList
      .filter((project) => project.checked)
      .map((project) => project.projectId);

    if (projectIdList.length === 0) {
      return message.warning("삭제할 프로젝트를 선택해주세요.");
    }

    confirm({
      title: `${projectIdList.length}개의 프로젝트를 삭제하시겠습니까?`,
      icon: <ExclamationCircleFilled />,
      okText: "삭제",
      okType: "danger",
      cancelText: "취소",
      onOk() {
        setLoading(true);
        customAxios
          .post("/project/delete", projectIdList)
          .then((res) => {
            setLoading(false);
            getProjectList();
            message.success("성공적으로 삭제되었습니다.");
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
            message.error("삭제 중 오류가 발생하였습니다.");
          });
      }
    });
  };

  const getHashFilterOption = () => {
    try {
      if (
        location.hash !== undefined &&
        location.hash !== null &&
        location.hash !== ""
      ) {
        const hash = location.hash.substring(1, location.hash.length);

        const filterOptions = JSON.parse(decodeURI(hash));
        filterOptions.page = parseInt(filterOptions.page) || 1;

        return JSON.parse(decodeURI(hash));
      }
    } catch (err) {}

    return {
      search: "",
      orderColumn: "projectPriority",
      order: "desc",
      count: 10,
      page: 1
    };
  };

  const [filterOption, setFilterOption] = useState(getHashFilterOption());
  const [totalPage, setTotalPage] = useState(1);
  const [showProjectList, setShowProjectList] = useState([]);

  useEffect(() => {
    if (projects) {
      const _showProjectList = projects
        .filter((project) =>
          [project.projectTitle, project.projectClient].some(
            (val) => val && val.includes(filterOption.search)
          )
        )
        .sort((a, b) => {
          if (filterOption.orderColumn === "projectPriority") {
            if (
              parseInt(a[filterOption.orderColumn], 10) <
              parseInt(b[filterOption.orderColumn], 10)
            ) {
              return filterOption.order === "asc" ? -1 : 1;
            }
            if (
              parseInt(a[filterOption.orderColumn], 10) >
              parseInt(b[filterOption.orderColumn], 10)
            ) {
              return filterOption.order === "asc" ? 1 : -1;
            }
          } else {
            if (a[filterOption.orderColumn] < b[filterOption.orderColumn]) {
              return filterOption.order === "asc" ? -1 : 1;
            }
            if (a[filterOption.orderColumn] > b[filterOption.orderColumn]) {
              return filterOption.order === "asc" ? 1 : -1;
            }
          }
          return 0;
        });

      setTotalPage(Math.ceil(_showProjectList.length / filterOption.count));

      const startIndex = (filterOption.page - 1) * filterOption.count;
      const endIndex = filterOption.page * filterOption.count;
      setShowProjectList(_showProjectList.slice(startIndex, endIndex));

      window.history.pushState({}, "", `#${JSON.stringify(filterOption)}`);
    }
  }, [projects, filterOption]);

  const changeFilterOption = (option) => {
    if (option.search || option.count) {
      option.page = 1;
    }
    setFilterOption({ ...filterOption, ...option });
  };

  const sortClicked = (orderColumn) => {
    if (filterOption.orderColumn === orderColumn) {
      changeFilterOption({
        order: filterOption.order === "asc" ? "desc" : "asc"
      });
    } else {
      changeFilterOption({
        orderColumn,
        order: "asc"
      });
    }
  };

  const getSortClassName = (orderColumn) => {
    if (filterOption.orderColumn === orderColumn) {
      return filterOption.order === "asc" ? "sort-up" : "sort-down";
    }
    return "";
  };

  const [allChecked, setAllChecked] = useState(false);

  const allCheckClicked = () => {
    const checked = !allChecked;
    setAllChecked(checked);
    setShowProjectList(
      showProjectList.map((project) => ({ ...project, checked }))
    );
  };

  const projectCheckClicked = (project) => {
    const checked = !project.checked;

    project.checked = checked;
    if (checked && showProjectList.every((project) => project.checked)) {
      setAllChecked(true);
    } else if (!checked && allChecked) {
      setAllChecked(false);
    } else {
      setShowProjectList([...showProjectList]);
    }
  };

  const projectListSorted = (project, newIndex) => {
    const projectId = project.projectId;
    const oldIndex = project.projectPriority;

    if (
      [oldIndex, newIndex, projectId].some((v) => v === undefined || v === null)
    ) {
      console.error("parameter 오류");
      return message.error("처리 중 오류가 발생하였습니다.");
    }

    setLoading(true);

    customAxios
      .patch(`/project/priority/${projectId}`, { oldIndex, newIndex })
      .then((res) => {
        setLoading(false);
        getProjectList();
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        return message.error("정렬 순서 변경 중 오류가 발생하였습니다.");
      });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">프로젝트 현황</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">프로젝트 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                프로젝트 현황
              </li>
            </ol>
          </div>
        </div>
        <button
          className="btns btns-icon-before"
          type="button"
          onClick={createButtonClicked}
        >
          <i className="icon-useradd"></i>프로젝트 등록
        </button>
      </div>
      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col">
                <div className="card-items">
                  <div className="table-wrap tb-member-list">
                    <div className="tb-head-wrap">
                      <div className="search-wrap">
                        <div className="input-box search">
                          <input
                            type="text"
                            placeholder="고객사명, 프로젝트명 검색"
                            onKeyUp={({ target }) =>
                              changeFilterOption({ search: target.value })
                            }
                          />
                          <button type="button" className="btn-search">
                            <i className="icon-search"></i>
                          </button>
                        </div>
                      </div>

                      <div className="tb-toolbar">
                        <div className="btn-wrap">
                          <button
                            className="btns btns-outline btns-icon-before btns-md"
                            type="button"
                            onClick={deleteButtonClicked}
                          >
                            <i className="icon-chattext"></i>삭제
                          </button>
                        </div>
                        <div className="select-box">
                          <select
                            value={filterOption.count}
                            onChange={({ target }) =>
                              changeFilterOption({
                                count: target.value
                              })
                            }
                          >
                            <option value="10">10개</option>
                            <option value="20">20개</option>
                            <option value="30">30개</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="tb-overflow">
                      <table className="table">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th scope="col">
                              <label className="checkbox-wrap">
                                <input
                                  type="checkbox"
                                  name="check-all"
                                  checked={allChecked}
                                  onClick={() => allCheckClicked()}
                                  onChange={() => {}}
                                />
                                <span className="label-checkbox"></span>
                              </label>
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "projectClient"
                              )}`}
                              onClick={() => sortClicked("projectClient")}
                            >
                              고객사명
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "projectCode"
                              )}`}
                              onClick={() => sortClicked("projectCode")}
                            >
                              카테고리명
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "projectTitle"
                              )}`}
                              onClick={() => sortClicked("projectTitle")}
                            >
                              프로젝트명
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "projectViewYn"
                              )}`}
                              onClick={() => sortClicked("projectViewYn")}
                            >
                              사용 여부
                            </th>
                            <th
                              scope="col"
                              className={`sort ${getSortClassName(
                                "projectPriority"
                              )}`}
                              onClick={() => sortClicked("projectPriority")}
                            >
                              노출순서
                            </th>
                            <th scope="col">상세</th>
                          </tr>
                        </thead>
                        <tbody>
                          {showProjectList !== null &&
                            showProjectList.map((project) => (
                              <tr key={project.projectId}>
                                <td>
                                  <label className="checkbox-wrap">
                                    <input
                                      type="checkbox"
                                      name={project.projectId}
                                      checked={project.checked}
                                      onClick={() =>
                                        projectCheckClicked(project)
                                      }
                                      onChange={() => {}}
                                    />
                                    <span className="label-checkbox"></span>
                                  </label>
                                </td>
                                <td>{project.projectClient}</td>
                                <td>{project.projectCodeName}</td>
                                <td>{project.projectTitle}</td>
                                <td>{`${
                                  project.projectViewYn === "Y"
                                    ? "사용"
                                    : "미사용"
                                }`}</td>
                                <td>
                                  <div className="select-box">
                                    <select
                                      value={project.projectPriority}
                                      onChange={({ target }) =>
                                        projectListSorted(project, target.value)
                                      }
                                    >
                                      <NumberToOptions
                                        number={projects.length}
                                      />
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    className="btns btns-gray btns-sm detail"
                                    type="button"
                                    onClick={() =>
                                      detailButtonClicked(project.projectId)
                                    }
                                  >
                                    상세보기
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="paging-wp">
                      <button
                        type="button"
                        className="btn-prev"
                        onClick={() =>
                          changeFilterOption({ page: filterOption.page - 1 })
                        }
                        disabled={filterOption.page === 1}
                      >
                        <i className="icon-arrow-left"></i>
                      </button>
                      {Array.from(
                        { length: totalPage },
                        (v, i) => (v = i + 1)
                      ).map((val) => (
                        <button
                          type="button"
                          className={`${
                            val === filterOption.page ? "active" : ""
                          }`}
                          onClick={() => changeFilterOption({ page: val })}
                          key={`pageNavigateButton${val}`}
                        >
                          {val}
                        </button>
                      ))}
                      <button
                        type="button"
                        className="btn-next"
                        onClick={() =>
                          changeFilterOption({ page: filterOption.page + 1 })
                        }
                        disabled={filterOption.page === totalPage}
                      >
                        <i className="icon-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ProjectList;
