import { message } from "antd";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import customAxios from "src/common/axios";
import { useUserActions } from "src/contexts/userProvider";

function Login() {
  const navigate = useNavigate();
  const actions = useUserActions();

  const idRef = useRef();
  const pwRef = useRef();
  const rememberIdRef = useRef();
  const [showPw, setShowPw] = useState(false);

  const login = () => {
    const id = idRef.current.value;
    const pw = pwRef.current.value;
    const rememberId = rememberIdRef.current.checked;

    idRef.current.parentElement.className = "input-wrap";
    pwRef.current.parentElement.className = "input-wrap";

    if (!id || !pw) {
      if (!id) {
        idRef.current.parentElement.className = "input-wrap is-invalid";
      } else {
        pwRef.current.parentElement.className = "input-wrap is-invalid";
      }
      return message.error("아이디와 비밀번호를 입력하세요.");
    }

    if (rememberId) {
      localStorage.setItem("rememberedId", id);
    } else {
      localStorage.removeItem("rememberedId");
    }

    customAxios
      .post("/admin/login", { adminId: id, adminPwd: pw })
      .then((res) => {
        const token = res.headers["jwt-auth-token"];
        if (!token) {
          console.error("토큰 없음");
          return message.error("잠시 후 다시 시도해주세요.");
        }
        sessionStorage.setItem("token", token);

        const result = actions.login({
          ...res.data.data
        });

        if (result) {
          setTimeout(() => {
            navigate("/category/list");
          }, 100);
        }
      })
      .catch((err) => {
        message.error("아이디와 비밀번호를 확인해주세요.");
        return err;
      });
  };

  const resetClassName = (ref) => {
    ref.current.className = "";
  };

  const pwViewToggleClicked = () => {
    if (showPw) {
      document.querySelector(".btn-eye").classList.add("show");
    } else {
      document.querySelector(".btn-eye").classList.remove("show");
    }
    setShowPw(!showPw);
  };

  useEffect(() => {
    const rememberedId = localStorage.getItem("rememberedId");
    if (rememberedId) {
      idRef.current.value = rememberedId;
      rememberIdRef.current.checked = true;
    }
  }, []);

  return (
    <div id="InfyLogin">
      <a href="" className="logo"></a>
      <div className="login-wrap">
        <h2 className="text">관리자 로그인</h2>
        <form className="login-form">
          <div className="formbox">
            <p className="label">아이디</p>
            <div className="input-wrap" id="validationId">
              <input
                type="text"
                className="input"
                name=""
                placeholder="아이디를 입력하세요."
                id="validationId"
                ref={idRef}
                defaultValue={localStorage.getItem("rememberedId")}
                onKeyUp={() => resetClassName(idRef)}
              />
            </div>
            <p id="validationId" className="invalid-feedback">
              아이디를 확인해주세요.
            </p>
          </div>
          <div className="formbox">
            <p className="label">패스워드</p>
            <div className="input-wrap" id="validationPw">
              <button
                type="button"
                className="btn-eye show"
                onClick={pwViewToggleClicked}
              ></button>
              <input
                type={showPw ? "text" : "password"}
                className="input"
                name=""
                placeholder="패스워드를 입력하세요."
                ref={pwRef}
                id="validationPw"
                onKeyUp={() => resetClassName(pwRef)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    login();
                  }
                }}
              />
            </div>
            <p id="validationPw" className="invalid-feedback">
              패스워드를 확인해주세요.
            </p>
          </div>
          <label className="checkbox">
            <input type="checkbox" name="checkbox" ref={rememberIdRef} />
            <span className="label-checkbox">아이디 기억하기</span>
          </label>
          <button type="button" className="btn-login" onClick={login}>
            로그인
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
