import React, { useEffect, useState } from "react";
import customAxios from "src/common/axios";
import ImageSingleUpload from "src/components/common/ImageSingleUpload";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";

function ProjectCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projectClient, setProjectClient] = useState("");
  const [projectCode, setProjectCode] = useState(null);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectMainImg, setProjectMainImg] = useState([]);
  const [projectSubImg, setProjectSubImg] = useState([]);
  const [projectViewYn, setProjectViewYn] = useState("Y");
  const [categoryList, setCategoryList] = useState(null);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    setLoading(true);

    customAxios
      .get("/category/list")
      .then((res) => {
        setCategoryList(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        message.error("카테고리 목록을 불러오는 중 에러가 발생하였습니다.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const listButtonClicked = () => {
    navigate("/project/list");
  };

  const createProject = () => {
    const projectData = {
      projectClient,
      projectCode,
      projectTitle,
      projectViewYn
    };

    if (
      Object.values(projectData).some(
        (v) => v === undefined || v === null || v === ""
      )
    ) {
      return message.warning("모든 정보를 입력해주세요.");
    }

    if (!projectMainImg) {
      return message.warning("썸네일 이미지를 첨부해주세요.");
    }

    setLoading(true);

    customAxios
      .post("/project/insert", projectData)
      .then(async (insertData) => {
        const id = insertData.data.data.projectId;

        Promise.all([
          uploadImageFile(projectMainImg, "main", id),
          uploadImageFile(projectSubImg, "sub", id)
        ])
          .then((res) => {
            message.success("성공적으로 등록되었습니다.");
            navigate(`/project/${id}`);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        message.error("등록 중 오류가 발생하였습니다.");
        setLoading(false);
      });
  };

  const uploadImageFile = async (file, type, id) => {
    return new Promise((resolve, reject) => {
      if (!file || !type) return resolve();

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);

      customAxios
        .post(`/project/file/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          return resolve();
        })
        .catch((err) => {
          console.error(err);
          message.error("이미지 저장 중 오류가 발생하였습니다");
          return reject(err);
        });
    });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">프로젝트 등록</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">프로젝트 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                프로젝트 등록
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col col-12">
                <div className="card-items h-auto">
                  <div className="content-display operate-write">
                    <div className="cont-row-wrap">
                      <div className="cont-wrap">
                        <div className="list-cont">
                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">고객사</p>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="고객사를 입력하세요"
                                  value={projectClient}
                                  onChange={(e) =>
                                    setProjectClient(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">카테고리명</p>
                              <div className="select-wrap">
                                <div className="select-box">
                                  <select
                                    value={projectCode}
                                    onChange={({ target }) => {
                                      setProjectCode(target.value);
                                    }}
                                  >
                                    <option value="">선택</option>
                                    {categoryList &&
                                      categoryList.map((category) => (
                                        <option
                                          key={category.categoryId}
                                          value={category.categoryId}
                                        >
                                          {category.categoryName}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">프로젝트명</p>
                                <input
                                  type="text"
                                  placeholder="프로젝트명을 입력하세요"
                                  value={projectTitle}
                                  onChange={({ target }) =>
                                    setProjectTitle(target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">썸네일 이미지</p>
                              <ImageSingleUpload
                                onChange={(file) => {
                                  setProjectMainImg(file);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">상세 이미지</p>
                              <ImageSingleUpload
                                onChange={(file) => {
                                  setProjectSubImg(file);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">사용 여부</p>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={projectViewYn === "Y"}
                                  onChange={() => setProjectViewYn("Y")}
                                />
                                <span className="label-radio" /> 사용
                              </label>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={projectViewYn === "N"}
                                  onChange={() => setProjectViewYn("N")}
                                />
                                <span className="label-radio" /> 미사용
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="cont-btns-wrap">
                        <div className="btns-wrap"></div>
                        <div className="btns-wrap">
                          <button
                            type="button"
                            className="btns btns-outline"
                            onClick={listButtonClicked}
                          >
                            목록
                          </button>
                          <button
                            type="button"
                            className="btns"
                            onClick={createProject}
                          >
                            등록
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ProjectCreate;
