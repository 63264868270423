import React, { useEffect, useState } from "react";
import customAxios from "src/common/axios";
import { Modal, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import * as paths from "src/routes/paths";

function CategoryDetail() {
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState(null); // 수정 취소 시, 복구하기 위한 원본데이터
  const params = useParams();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [categoryViewYn, setCategoryViewYn] = useState("Y");

  const { confirm } = Modal;

  const deleteButtonClicked = () => {
    confirm({
      title: "정말로 삭제하시겠습니까?",
      icon: <ExclamationCircleFilled />,
      okText: "삭제",
      okType: "danger",
      cancelText: "취소",
      onOk() {
        setLoading(true);
        customAxios
          .post("/category/delete", [params.id])
          .then((res) => {
            message.success("성공적으로 삭제되었습니다.");
            navigate(paths.getPath(paths.category, paths.categoryList));
          })
          .catch((err) => {
            console.error(err);
            message.error("삭제 중 오류가 발생하였습니다.");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const listButtonClicked = () => {
    navigate(paths.getPath(paths.category, paths.categoryList));
  };

  useEffect(() => {
    getCategoryInfo();
  }, []);

  const getCategoryInfo = () => {
    setLoading(true);
    customAxios
      .get(`/category/${params.id}`)
      .then((res) => {
        setLoading(false);
        setCategoryInfo(res.data.data);
        setCategoryState(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        message.error("프로젝트 정보를 불러오는 중 에러가 발생하였습니다F.");
      });
  };

  const setCategoryState = (category) => {
    setCategoryName(category.categoryName);
    setCategoryViewYn(category.categoryViewYn);
  };

  const updateCategory = () => {
    const categoryData = {
      categoryName,
      categoryViewYn
    };

    if (
      Object.values(categoryData).some(
        (v) => v === undefined || v === null || v === ""
      )
    ) {
      return message.warning("모든 정보를 입력해주세요.");
    }

    setLoading(true);

    customAxios
      .patch(`/category/${params.id}`, categoryData)
      .then((res) => {
        setIsEditMode(false);
        setCategoryInfo(res.data.data);
        setCategoryState(res.data.data);
        message.success("성공적으로 수정되었습니다.");
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        message.error("수정 중 오류가 발생하였습니다.");
        setLoading(false);
      });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">
            카테고리 {`${!isEditMode ? "상세" : "수정"}`}
          </h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">카테고리 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                카테고리 {`${!isEditMode ? "상세" : "수정"}`}
              </li>
            </ol>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col col-12">
                <div className="card-items h-auto">
                  <div className="content-display operate-write">
                    <div className="cont-row-wrap">
                      <div className="cont-wrap">
                        <div className="list-cont">
                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">카테고리명</p>
                                {!isEditMode ? (
                                  <span>{categoryName}</span>
                                ) : (
                                  <input
                                    type="text"
                                    placeholder="카테고리명을 입력하세요"
                                    value={categoryName}
                                    onChange={({ target }) =>
                                      setCategoryName(target.value)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">사용 여부</p>
                              {!isEditMode ? (
                                <span>{`${
                                  categoryViewYn === "Y" ? "사용" : "미사용"
                                }`}</span>
                              ) : (
                                <>
                                  <label className="radio-wrap">
                                    <input
                                      type="radio"
                                      name="isUse"
                                      checked={categoryViewYn === "Y"}
                                      onChange={() => setCategoryViewYn("Y")}
                                    />
                                    <span className="label-radio" /> 사용
                                  </label>
                                  <label className="radio-wrap">
                                    <input
                                      type="radio"
                                      name="isUse"
                                      checked={categoryViewYn === "N"}
                                      onChange={() => setCategoryViewYn("N")}
                                    />
                                    <span className="label-radio" /> 미사용
                                  </label>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="cont-btns-wrap">
                        <div className="btns-wrap">
                          {!isEditMode ? (
                            <button
                              type="button"
                              className="btns btns-gray"
                              onClick={deleteButtonClicked}
                            >
                              삭제
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btns btns-gray"
                              onClick={() => {
                                setIsEditMode(false);
                                setCategoryState({ ...categoryInfo });
                              }}
                            >
                              취소
                            </button>
                          )}
                        </div>
                        <div className="btns-wrap">
                          {!isEditMode && (
                            <>
                              <button
                                type="button"
                                className="btns btns-outline"
                                onClick={listButtonClicked}
                              >
                                목록
                              </button>
                              <button
                                type="button"
                                className="btns"
                                onClick={() => setIsEditMode(true)}
                              >
                                수정
                              </button>
                            </>
                          )}
                          {isEditMode && (
                            <button
                              type="button"
                              className="btns"
                              onClick={updateCategory}
                            >
                              저장
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default CategoryDetail;
