import Login from "@/pages/Login";
import PrivateRoute from "./PrivateRouter";
import MainLayout from "src/layouts/MainLayout";
import CategoryList from "src/pages/category/CategoryList";
import CategoryCreate from "src/pages/category/CategoryCreate";
import CategoryDetail from "src/pages/category/CategoryDetail";
import ProjectList from "src/pages/project/ProjectList";
import ProjectCreate from "src/pages/project/ProjectCreate";
import ProjectDetail from "src/pages/project/ProjectDetail";
import AdminList from "src/pages/admin/AdminList";
import AdminCreate from "src/pages/admin/AdminCreate";
import AdminDetail from "src/pages/admin/AdminDetail";
import * as paths from "./paths";
import { CategoryProvider } from "src/contexts/categoryProvider";
import { ProjectProvider } from "src/contexts/projectProvider";
import { AdminProvider } from "src/contexts/adminProvider";

const router = [
  {
    index: true,
    element: <Login />
  },
  {
    path: paths.login,
    element: <Login />
  },
  {
    path: paths.category,
    element: (
      <CategoryProvider>
        <PrivateRoute authentication={true} Layout={MainLayout} />
      </CategoryProvider>
    ),
    children: [
      {
        path: paths.categoryList,
        element: <CategoryList />
      },
      {
        path: paths.categoryCreate,
        element: <CategoryCreate />
      },
      {
        path: paths.categoryDetail,
        element: <CategoryDetail />
      }
    ]
  },
  {
    path: paths.project,
    element: (
      <ProjectProvider>
        <PrivateRoute authentication={true} Layout={MainLayout} />
      </ProjectProvider>
    ),
    children: [
      {
        path: paths.projectList,
        element: <ProjectList />
      },
      {
        path: paths.projectCreate,
        element: <ProjectCreate />
      },
      {
        path: paths.projectDetail,
        element: <ProjectDetail />
      }
    ]
  },
  {
    path: paths.admin,
    element: (
      <AdminProvider>
        <PrivateRoute authentication={true} Layout={MainLayout} />
      </AdminProvider>
    ),
    children: [
      {
        path: paths.adminList,
        element: <AdminList />
      },
      {
        path: paths.adminCreate,
        element: <AdminCreate />
      },
      {
        path: paths.adminDetail,
        element: <AdminDetail />
      }
    ]
  }
];

export default router;
