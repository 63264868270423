import { createContext, useContext, useMemo, useState } from "react";

const ProjectValueContext = createContext();
const ProjectActionsContext = createContext();

export function ProjectProvider({ children }) {
  const [projects, setProjects] = useState(null);
  const actions = useMemo(
    () => ({
      setProjects: (data) => {
        setProjects(data);
      }
    }),
    []
  );

  return (
    <ProjectActionsContext.Provider value={actions}>
      <ProjectValueContext.Provider value={projects}>
        {children}
      </ProjectValueContext.Provider>
    </ProjectActionsContext.Provider>
  );
}

export function useProjectValue() {
  const value = useContext(ProjectValueContext);
  if (value === undefined) {
    throw new Error("useProjectValue should be used within ProjectProvider");
  }
  return value;
}

export function useProjectActions() {
  const value = useContext(ProjectActionsContext);
  if (value === undefined) {
    throw new Error("useProjectActions should be used within ProjectProvider");
  }
  return value;
}
