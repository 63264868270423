import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import router from "@/routes";
import { UserProvider } from "./contexts/userProvider";
import "@/styles/reset.css";
import "@/styles/bootstrap.min.css";
import "@/styles/scss/style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
const browserRouter = createBrowserRouter(router);
root.render(
  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={browserRouter} />
    </UserProvider>
  </React.StrictMode>
);
