import React, { useState } from "react";
import customAxios from "src/common/axios";
import { Spin, message } from "antd";
import { useNavigate } from "react-router-dom";

function AdminCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [adminName, setAdminName] = useState(null);
  const [adminDescription, setAdminDescription] = useState("");
  const [adminPwd, setAdminPwd] = useState("");
  const [adminActiveYn, setAdminActiveYn] = useState("Y");

  const listButtonClicked = () => {
    navigate("/admin/list");
  };

  const createAdmin = () => {
    const adminData = {
      adminId,
      adminName,
      adminDescription,
      adminPwd,
      adminActiveYn
    };

    if (
      Object.values(adminData).some(
        (v) => v === undefined || v === null || v === ""
      )
    ) {
      return message.warning("모든 정보를 입력해주세요.");
    }

    setLoading(true);

    customAxios
      .post("/admin/insert", adminData)
      .then((res) => {
        const id = res.data.data.adminId;
        message.success("성공적으로 등록되었습니다.");
        navigate(`/admin/${id}`);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          message.warning("이미 사용중인 아이디입니다.");
        } else {
          console.error(err);
          message.error("등록 중 오류가 발생하였습니다.");
        }

        setLoading(false);
      });
  };

  return (
    <div className="project-management">
      <div className="title-cont-wrap">
        <div className="title-container">
          <h3 className="title">관리자 등록</h3>
          <div className="desc-wrap">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <a href="javascript:;">관리자 관리</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                관리자 등록
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div className="contents-wrap container-fluid">
          <div className="row-group">
            <div className="card-row row">
              <div className="col col-12">
                <div className="card-items h-auto">
                  <div className="content-display operate-write">
                    <div className="cont-row-wrap">
                      <div className="cont-wrap">
                        <div className="list-cont">
                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">아이디</p>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="아이디를 입력하세요"
                                  value={adminId}
                                  onChange={(e) => setAdminId(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">관리자명</p>
                                <input
                                  type="text"
                                  name=""
                                  placeholder="관리자명을 입력하세요"
                                  value={adminName}
                                  onChange={({ target }) => {
                                    setAdminName(target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">비밀번호</p>
                                <input
                                  type="password"
                                  name=""
                                  placeholder="비밀번호를 입력하세요"
                                  value={adminPwd}
                                  onChange={({ target }) => {
                                    setAdminPwd(target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="input-box">
                                <p className="txt">설명</p>
                                <input
                                  type="text"
                                  placeholder="설명을 입력하세요"
                                  value={adminDescription}
                                  onChange={({ target }) =>
                                    setAdminDescription(target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <p className="txt">사용 여부</p>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={adminActiveYn === "Y"}
                                  onChange={() => setAdminActiveYn("Y")}
                                />
                                <span className="label-radio" /> 사용
                              </label>
                              <label className="radio-wrap">
                                <input
                                  type="radio"
                                  name="isUse"
                                  checked={adminActiveYn === "N"}
                                  onChange={() => setAdminActiveYn("N")}
                                />
                                <span className="label-radio" /> 미사용
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="cont-btns-wrap">
                        <div className="btns-wrap"></div>
                        <div className="btns-wrap">
                          <button
                            type="button"
                            className="btns btns-outline"
                            onClick={listButtonClicked}
                          >
                            목록
                          </button>
                          <button
                            type="button"
                            className="btns"
                            onClick={createAdmin}
                          >
                            등록
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default AdminCreate;
