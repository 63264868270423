export default function NumberToOptions({ number }) {
  if (number === null || number === undefined || Number.isNaN(number)) {
    return <></>;
  }

  return (
    <>
      {Array.from({ length: number }, (v, i) => (v = i + 1)).map((val) => (
        <option key={`${number} option ${val}`} value={val}>
          {val}
        </option>
      ))}
    </>
  );
}
