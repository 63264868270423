import { createContext, useContext, useMemo, useState } from "react";

const UserValueContext = createContext();
const UserActionsContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || null
  );
  const actions = useMemo(
    () => ({
      login(user) {
        sessionStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        return true;
      },
      logout() {
        setUser(null);
        return true;
      }
    }),
    []
  );

  return (
    <UserActionsContext.Provider value={actions}>
      <UserValueContext.Provider value={user}>
        {children}
      </UserValueContext.Provider>
    </UserActionsContext.Provider>
  );
}

export function useUserValue() {
  const value = useContext(UserValueContext);
  if (value === undefined) {
    throw new Error("useUserValue should be used within UserProvider");
  }
  return value;
}

export function useUserActions() {
  const value = useContext(UserActionsContext);
  if (value === undefined) {
    throw new Error("useUserActions should be used within UserProvider");
  }
  return value;
}
